@import "~antd/dist/antd.less";

body {
  background: rgb(210, 214, 206) !important;
  color: rgb(54, 60, 60) !important;
  font-family: "Segoe UI", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Bebas Neue", sans-serif !important;
  margin-bottom: 0 !important;
}

p {
  margin-bottom: 0 !important;
}

.primary-color {
  color: rgb(172, 28, 62);
}

#root {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
}

.ant-collapse-header {
  font-family: "Bebas Neue", sans-serif !important;
  font-size: 1.5rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;