.page-header__text {
  font-size: 44px;
  font-family: "Bebas Neue", sans-serif;
}

.page-header__info {
  font-size: 18px;
  color: rgb(104, 104, 104);
}

.page-header__icon {
  color: rgb(104, 104, 104);
  padding-left: 0.5rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;