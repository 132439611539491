.ticket {
  display: inline-block;
  background: rgb(247, 240, 227);
  padding: 10px;
  box-shadow: 1px 1px 3px rgba(18, 5, 88, 0.3);
  border-left: 1px dashed rgba(172, 28, 62, 0.3);
  border-right: 1px dashed rgba(172, 28, 62, 0.3);
  font-family: "Bebas Neue", sans-serif;
}

.ticket__inner {
  border: 1px solid rgb(172, 28, 62);
}

.ticket__teams {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticket__team-container {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}

.ticket__team-name {
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
}

@media only screen and (max-width: 500px) {
  .ticket__team-name {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
  }
}

@media only screen and (max-width: 300px) {
  .ticket__team-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }
}

.ticket__team-spread {
  font-size: 18px;
  line-height: 0.75;
}

.ticket__team-vs {
  flex-shrink: 1;
  font-size: 24px;
  font-weight: bold;
  line-height: 0.5;
  padding: 10px;
  color: rgba(200, 0, 47, 0.25);
  text-decoration: underline;
}

.ticket__pick-header {
  border-bottom: 1px solid rgb(172, 28, 62);
  border-top: 1px solid rgb(172, 28, 62);
  font-size: 24px;
  color: rgba(200, 0, 47, 0.5);
}

.ticket__pick-checkboxes {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticket__pick-checkbox {
  flex-grow: 1;
  font-size: 24px;
}

.ticket__pick-checkbox-border-right {
  border-right: 1px solid rgb(172, 28, 62);
}

.ticket__pick-checkbox-border-left {
  border-left: 1px solid rgb(172, 28, 62);
}

.ticket__pick-sign-in-text {
  text-align: center;
  font-size: 18px;
  color: rgba(29, 2, 9, 0.5);
}

.ticket__pick-disabled {
  opacity: 0.05;
}

.ticket__pick-checkbox-push {
  opacity: 0.5;
  margin-left: 0.5rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;