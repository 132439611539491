.calculate-winners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.calculate-winners__button {
  margin-top: 2rem;
}

.calculate-winners__error {
  margin: 1rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;