.scoreboard {
  background: #363c3c;
  padding: 10px;
  font-family: "Bebas Neue", sans-serif;
}
.scoreboard__inner {
  border: 2px dotted #aaaaaa;
  padding: 10px;
}
.scoreboard__header {
  color: white;
  font-size: 36px;
  line-height: 1.5;
}
.scoreboard__header-icon {
  margin: 0 0.5rem;
  font-size: 30px;
  color: #ec0a3f;
}
.scoreboard__stats-label {
  color: #dddddd;
  border-top: 1px solid #636363;
  padding: 5px;
  font-size: 28px;
}
.scoreboard__stats-label-bold {
  margin-left: 1rem;
  color: #ec0a3f;
}
.scoreboard__stats-label-percent {
  font-size: 22px;
  opacity: 0.5;
}
