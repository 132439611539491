.stats-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-page__cta {
  margin-bottom: 2rem;
}

.admin__button {
  margin-top: 2rem;
}

.stats-page__collapse {
  flex-grow: 1;
}

.stats-panel__download {
  margin-top: 1rem;
}

.stats-page__web-share {
  margin: 2rem 0;
  opacity: 0.5;
  font-size: 18px;
}

.stats-panel__picks-count {
  font-weight: 600;
}

.stats-panel__active-week {
  opacity: 0.5;
  font-size: 18px;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;