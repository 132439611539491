.app-header {
  flex-shrink: 1;
}

.app-content {
  text-align: center;
  flex-grow: 1;
  padding: 0.5rem;
}

.app-footer {
  flex-shrink: 1;
}

.app-container {
  padding: 1rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;