.create-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.create-schedule__ready {
  margin: 0.5rem;
}

.game-form {
  display: flex;
  flex-direction: column;
  background: rgb(247, 240, 227);
  box-shadow: 1px 1px 3px rgba(18, 5, 88, 0.3);
  border-left: 1px dashed rgba(172, 28, 62, 0.3);
  border-right: 1px dashed rgba(172, 28, 62, 0.3);
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 1rem;
}

.game-form__team-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgb(172, 28, 62);
  padding: 0.5rem;
}

.game-form__spread-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;
}

.game-form__spread-label {
  margin-right: 1rem;
  opacity: 0.5;
}

.game-form__winner-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.game-form__winner-label {
  margin-right: 1rem;
  opacity: 0.5;
}

.game-form__error-label {
  margin: 0.5rem;
  color: rgb(172, 28, 62);
  font-size: 20px;
}

.game-form__error-icon {
  margin-right: 0.5rem;
}

.game-form__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  padding-left: 1rem;
}

.game-form__title {
  flex-grow: 1;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;