.footer {
  margin-top: 2rem;
  background: rgb(7, 7, 7);
  border-top: 16px solid rgba(172, 28, 62);
  /* border-top: 16px solid  rgb(7, 7, 7);
  background:rgba(172, 28, 62); */
  width: 100%;
  padding: 2rem;
  text-align: center;
  color: rgba(210, 214, 206, 0.685);
  font-family: "Bebas Neue", sans-serif;
}

.footer__image {
  background: rgb(210, 214, 206) !important;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;