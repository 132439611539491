.leaderboard-page {
  align-items: center;
  margin: 2rem;
}
@media only screen and (max-width: 500px) {
  .leaderboard-page {
    margin: 0.5rem 0;
  }
}
.leaderboard__error {
  margin: 2rem;
  font-size: 1.5rem;
}
.leaderboard__row-nick {
  background: #f3ced5;
}
.leaderboard__row-self {
  background: #dfdfdf;
}
.leaderboard__tags-name {
  margin-right: 0.5rem;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.5rem;
}
.leaderboard__tags-picks {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.5rem;
  line-height: 1;
}
.leaderboard__tags-tag {
  margin-right: 0.5rem;
}
.leaderboard__tags-picks__pct {
  color: #686868;
  font-size: 1rem;
}
.leaderboard__tags-picks__highlight {
  color: #ac1c3e;
}
.leaderboard-header__legend {
  font-size: 1rem;
  margin-top: 1rem;
}
