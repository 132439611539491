.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.header__text {
  margin-bottom: 0 !important;
}

.header__nav-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.5rem;
  color: white !important;
  margin: 0 1rem 0 0 !important;
  line-height: 1 !important;
}

.header-button {
  margin-left: 0.5rem;
  color: white;
  font-size: 1rem;
}

.header-button:hover {
  opacity: 0.5;
  color: white;
  border-color: white;
}

.header__nav {
  background: rgb(172, 28, 62);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.header__nav-spacer {
  flex-grow: 1;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;