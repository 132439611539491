.lock-week {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.lock-week__error {
  margin: 1rem;
}

.lock-week__ready {
  margin: 0.5rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;