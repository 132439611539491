.scoreboard {
  background: rgb(54, 60, 60);
  padding: 10px;
  font-family: "Bebas Neue", sans-serif;
}

.scoreboard__inner {
  border: 2px dotted rgb(170, 170, 170);
  padding: 10px;
}

.scoreboard__header {
  color: white;
  font-size: 36px;
  line-height: 1.5;
}

.scoreboard__header-icon {
  margin: 0 0.5rem;
  font-size: 30px;
  color: rgb(236, 10, 63);
}

.scoreboard__stats-label {
  color: rgb(221, 221, 221);
  border-top: 1px solid rgb(99, 99, 99);
  padding: 5px;
  font-size: 28px;
}

.scoreboard__stats-label-bold {
  margin-left: 1rem;
  color: rgb(236, 10, 63);
}

.scoreboard__stats-label-percent {
  font-size: 22px;
  opacity: 0.5;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;