.pick-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.pick-page__tickets {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pick-page__ticket {
  margin-bottom: 1rem;
}

.pick-page__header-text {
  font-size: 44px;
  font-family: "Bebas Neue", sans-serif;
}

.pick-page__header-info {
  font-size: 18px;
  color: rgb(104, 104, 104);
}

.pick-page__header-lock {
  color: rgb(104, 104, 104);
  padding-left: 0.5rem;
}

.pick-page__header-auth {
  margin-top: 1rem;
}

.pick-page__error {
  margin: 1rem;
}

.pick-page__logo {
  margin: 1rem;
  height: 160px;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;