.pick-winners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.pick-winners__tickets {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pick-winners__ticket {
  margin-bottom: 1rem;
}

.pick-winners__error {
  margin: 1rem;
}

@primary-color: rgb(172, 28, 62);@link-color: rgb(172, 28, 62);@font-size-base: 20px;